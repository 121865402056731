import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import HaveQuestions from '../components/StillHaveQuestions'
import IconExtLink from '../components/IconExtLink'

const Financing = () => {
  return (
    <Layout className={'container mx-auto'} breadcrumbs={false}>
      <Seo
        description="Financing your luxury purchases has never been easier with Gray and Sons. Affirm makes financing easy, safe, and convenient for anyone looking to stretch their hard earned dollars into budget-friendly installments."
        title="Financing at Gray and Sons Jewelers"
      />
      <div className="w-10/12 max-w-[1366px] mx-auto flex justify-center items-center flex-col py-10">
        <StaticImage
          src="../images/affirm_financing.jpg"
          alt="Watches and Jewelry Financing"
          aria-label="Watches and Jewelry Financing"
        />
        <h1 className="text-3xl py-5">Financing your luxury purchases has never been easier</h1>
        <p className="max-w-[800px]">
          Gray & Sons has partnered with Affirm to make your purchasing decision a little easier.
          Instead of paying upfront and in full, now you can shop and enjoy immediately, while
          splitting up the price into affordable monthly payments. Affirm makes financing easy,
          safe, and convenient for anyone looking to stretch their hard earned dollars into
          budget-friendly installments.
        </p>
        <StaticImage
          src="../images/how-financing-works.jpg"
          alt="How to finance your purchase"
          aria-label="How to finance your purchase"
          class="my-10"
        />
        <h2 className="text-2xl py-5">Choose 12, 24, or 36 monthly payments.</h2>
        <p className="max-w-[800px]">
          Affirm has designed its loans to offer you flexibility and control over your budget.
          Affirm offers rates from as low as 0-36% APR based on your credit. Actual rates will be
          shown at checkout. For Example: A $950 purchase may cost $88.15 for 12 months at 20% APR
        </p>
        <p className="max-w-[800px] my-4">
          <b>Rates from 0–36% APR.</b> Payment options through Affirm are subject to an eligibility
          check and are provided by these lending partners:{' '}
          <a href="https://affirm.com/lenders" target="_blank" rel="nofollow noreferrer">
            affirm.com/lenders <IconExtLink className="size-5" />
          </a>
          . Options depend on your purchase amount, and a down payment may be required. CA
          residents: Loans by Affirm Loan Services, LLC are made or arranged pursuant to a
          California Finance Lenders Law license. For licenses and disclosures, see{' '}
          <a href="https://affirm.com/licenses" target="_blank" rel="nofollow noreferrer">
            affirm.com/licenses <IconExtLink className="size-5" />
          </a>
          . For example, a $800 purchase could be split into 12 monthly payments of $72.21 at 15%
          APR or 4 interest free payments of $200 every 2 weeks.
        </p>
        <h2 className="text-2xl py-5">Frequently Asked Questions</h2>
        <h3 className="text-xl py-5"> What information does Affirm require?</h3>
        <p className="max-w-[800px]">
          Your Affirm account is created using your name, email, mobile phone number, birthday, and
          last 4 digits of your SSN. This combination helps Affirm verify and protect your identity.
        </p>
        <h3 className="text-xl py-5"> How do I pay my bills?</h3>
        <p className="max-w-[800px]">
          You can pay your Affirm bills online at www.affirm.com/pay. They accept payment by debit
          card, bank transfer and check.
        </p>
        <h3 className="text-xl py-5">Does Affirm perform a credit check?</h3>
        <p className="max-w-[800px]">
          Yes, when you first create an Affirm account, Affirm perform a 'soft' credit check to help
          verify your identity and determine your eligibility for financing. This 'soft' credit
          check will not affect your credit score.
        </p>
        <h3 className="text-xl py-5">
          Why was I prompted to pay a down payment with a debit card?
        </h3>
        <p className="max-w-[800px]">
          Affirms tries hard to approve every purchase but sometimes can't approve the full amount.
          When this happens, Affirm provides a debit card down payment option so that you may still
          complete your purchase right away.
        </p>
        <h3 className="text-xl py-5">Why was I prompted for a checking account?</h3>
        <p className="max-w-[800px]">
          Affirm sometimes requires additional information to consider a credit application. Such
          information may include an active checking account, which helps Affirm verify your
          identity and your ability to repay the requested loan. If they prompt you for this
          information but you are unable to provide it, Affirm will be unable to approve your credit
          application.
        </p>
        <h3 className="text-xl py-5">Returns and restocking fees</h3>
        <p className="max-w-[800px]">
          10 % Restocking fee will be applied to all returns on the purchases that are subject to
          Affirm financing due to non-refundable transaction fees charged to the vendor.
        </p>
        <hr className="w-10/12 mx-auto mt-20" />
        <HaveQuestions />
      </div>
    </Layout>
  )
}

export default Financing

import React from 'react'

const IconExtLink = () => {
  return (
    <div className="inline mx-1">
      <svg
        className=" w-4 h-4 fill-gray-500 inline -translate-y-[.1rem]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <path
          d="M26.8,26.8H5.2V5.2h9.9V2.6H4.6c-1.1,0-2,0.9-2,2v22.9c0,1.1,0.9,2,2,2h22.9c1.1,0,2-0.9,2-2V17.3h-2.7V26.8z"/>
        <polygon
          className="group-hover:translate-x-[.1rem] group-hover:-translate-y-[.1rem] duration-100"
          points="18.6,2.6 18.6,5.3 24.7,5.3 14.6,15.5 16.5,17.4 26.7,7.3 26.7,13.4 29.4,13.4 29.4,2.6 "
        />
      </svg>
    </div>
  )
}
export default IconExtLink
